import React, { useEffect } from "react";
import Prism from "prismjs";
import SgLayout from "~layouts/sg-layout";
import CodeBlock from '~2-components/SgCodeBlock/SgCodeBlock';
import startCase from 'lodash.startcase';
import IconIllustration, { allIllustrations } from '~2-components/IconIllustration/IconIllustration';
import Icon, { allIconComponents } from '~2-components/IconInline/IconInline';

const illustrationExample = `import IconIllustration from '../icon/icon-illustration';

<IconIllustration
    icon={''} //Required, name of SVG in /assets/illustrations
    altText={''} //Optional, custom alt text. Defaults to 'IconName Icon'
/>
`;

const illustrationReturnEg = `//returns
<img src={iconUrl} alt="{altText | {icon} Icon" />
`;

const iconEg = `import Icon from '~2-components/IconInline/IconInline';

<Icon
    icon={''} //Required, name of SVG in /assets/icons
/>
`;

const SgIcons = () => {
    useEffect(() => {
        // call the highlightAll() function to style our code blocks
        Prism.highlightAll();
    });

    return (
        <SgLayout>
            <section className="sg-content">
                <div className="container">
                    <h1>Icons</h1>
                    <p>Supports 3 use cases:</p>
                    <ol>
                        <li>Ilustrations (inline img src)</li>
                        <li>Normal Icons (Inline SVG code as a React component)</li>
                        <li>Background CSS icons (inline svg src url in SCSS)</li>
                    </ol>
                    <h2>Illustration icons</h2>
                    <div className="sg-eg-icons eg-illustrations">
                        {Object.keys(allIllustrations).map(iconName => {
                            return (
                                <div className="eg-icon" key={iconName}>
                                    <IconIllustration icon={iconName} />
                                    <div className="icon-details">
                                        <h5>{startCase(iconName)}</h5>
                                        <p>{iconName}</p>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    <p>Adviceline has several icons that are SVG illustrations. They <strong>cannot</strong> be fed to SVGO for processing and clean up because thier complicated SVG code is lost.</p>
                    <CodeBlock
                        code={illustrationExample}
                    />
                    <CodeBlock
                        code={illustrationReturnEg}
                        language={`markup`}
                    />
                    <h2>Icons</h2>
                    <div className="sg-eg-icons">
                        {Object.keys(allIconComponents).map(iconName => {
                            return (
                                <div className="eg-icon" key={iconName}>
                                    <Icon icon={iconName} />
                                    <div className="icon-details">
                                        <h5>{startCase(iconName)}</h5>
                                        <p>{iconName}</p>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    <p>Fed through SVGO to remove cruft, then import as a React Component for ease of use. Can change thier <code>color, stroke, and fill</code> values.</p>
                    <CodeBlock
                        code={iconEg}
                    />
                    <h3>Example with changing colours</h3>
                    <div className="sg-eg-icons eg-bg-change">
                        {Object.keys(allIconComponents).map(iconName => {
                            return (
                                <div className="eg-icon" key={iconName}>
                                    <Icon  icon={iconName} />
                                    <div className="icon-details">
                                        <h5>{startCase(iconName)}</h5>
                                        <p>{iconName}</p>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    <h2>Background CSS icons</h2>
                    <div className="sg-eg-icons eg-css-icon">
                        <div className="eg-bg-icon cross"></div>
                        <div className="eg-bg-icon arrow-left"></div>
                        <div className="eg-bg-icon phone"></div>
                        <div className="eg-bg-icon envelope"></div>
                    </div>
                    <h3>CSS examples changing colour</h3>
                    <div className="sg-eg-icons eg-css-icon">
                        <div className="eg-bg-icon mask cross"></div>
                        <div className="eg-bg-icon mask arrow-left"></div>
                        <div className="eg-bg-icon mask phone"></div>
                        <div className="eg-bg-icon mask envelope"></div>
                    </div>
                </div>
            </section>
        </SgLayout>
    );
};

export default SgIcons;
